<template>
  <k-vuelidate-error-extractor
    :validator="validator"
    class="basic-multi-select position-relative"
  >
    <multiselect
      :value="value"
      ref="multiselect"
      class="vue-multiselect-ps"
      :close-on-select="!multiple"
      :multiple="multiple"
      :required="required"
      :allowEmpty="!required"
      :options="options"
      :label="labelBy"
      :loading="loading"
      trackBy="Id"
      v-bind="$attrs"
      v-on="$listeners"
      @search-change="SearchChange"
      @open="CalcDropDown"
    >
      <template slot="maxElements">
        Minden elem kiválasztva
      </template>
      <template slot="noResult">
        Nincs találat
      </template>
      <template slot="noOptions">
        Kezdjen el gépelni...
      </template>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
        ><slot :name="slot" v-bind="scope"
      /></template>
    </multiselect>
    <span
      v-if="!multiple && value && !required"
      class="single-select-clear"
      @click="Clear"
    >
      <i class="fa fa-times"></i>
    </span>
  </k-vuelidate-error-extractor>
</template>

<script>
import $ from 'jquery';
import { getAllScrollParent, getScrollParent } from '../../utils/common';
let ct = 0;
export default {
  name: 'basic-multi-select',
  inheritAttrs: false,
  data() {
    return { scrollParents: [] };
  },
  mounted() {
    const ref = this.$refs.multiselect;
    if (ref) {
      this.scrollParents = getAllScrollParent(ref.$el);
    }

    this.scrollParents.forEach(element => {
      element.addEventListener('scroll', this.OnParentScroll);
    });
  },
  beforeDestroy() {
    this.scrollParents.forEach(element => {
      element.removeEventListener('scroll', this.OnParentScroll);
    });
  },
  methods: {
    async SearchChange() {},
    async OnParentScroll(e) {
      this.CalcDropDown();
    },
    async CalcDropDown() {
      await this.$nextTick();
      const ref = this.$refs.multiselect;
      if (!this.$el || typeof this.$el.getBoundingClientRect !== 'function') {
        return;
      }
      const { top, height, width, left } = this.$el.getBoundingClientRect();
      if (ref) {
        let list = ref.$refs.list;
        let windowHeight = window.innerHeight;
        let popupTopPos = top + height;
        let popupMaxHeight = 30;
        try {
          let dropdown = this.$el.getElementsByClassName(
            'multiselect__content-wrapper'
          )[0];
          if (dropdown) {
            popupMaxHeight = dropdown.getBoundingClientRect().height;
          }
        } catch (error) {
          console.error(error);
        }
        if (popupTopPos + popupMaxHeight > windowHeight) {
          // Nem fér ki alul
          if (popupTopPos - popupMaxHeight - height > 10) {
            //Elfér felül
            let offset = popupMaxHeight + height;
            popupTopPos = popupTopPos - offset;
          } else {
            //Eltolás lentről
            let offset = popupTopPos + popupMaxHeight + 10 - windowHeight;
            popupTopPos = popupTopPos - offset;
          }
        }
        list.style.bottom = 'auto';
        list.style.top = `${popupTopPos}px`;
        list.style.width = `${width}px`;
        list.style.position = 'fixed';
        list.style.left = `${left}px`;
      }
    },
    Clear() {
      if (this.multiple) {
        this.$emit('input', []);
      } else {
        this.$emit('input', null);
      }
    },
  },
  watch: {
    value: {
      handler() {
        this.CalcDropDown();
      },
      deep: true,
    },
    options: {
      handler() {
        this.CalcDropDown();
      },
      deep: true,
    },
  },
  props: {
    id: {
      type: String,
      default: function() {
        return `Input_${+new Date()}_${ct++}`;
      },
    },
    value: {
      default: '',
      required: true,
    },
    validator: {
      type: Object,
      default: function() {
        return {};
      },
    },
    labelBy: {
      type: String,
      default: 'text',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {},
  },
};
</script>
<style lang="scss" scoped>
.basic-multi-select::v-deep {
 
  .multiselect {
    .multiselect__select {
      height: 33px;
    }
    .multiselect__tags {
      border-color: #ced4da;
      min-height: 35px;
      padding-left: 12px;

      .multiselect__placeholder {
        color: rgba(73, 80, 87, 0.8);
        margin-bottom: 0;
        padding-top: 0;
      }
      .multiselect__input,
      .multiselect__single {
        margin-bottom: 0;
        min-height: 30px;
        padding: 0;
        display: flex;
        align-items: center;
        font-size: 0.875rem;
      }
      .multiselect__single {
        min-height: unset;
        padding-right: 10px;
      }
      .multiselect__tag {
        background: #3246d2;
        margin-bottom: 0;
        margin-right: 5px;
        padding-top: 6px;

        .multiselect__tag-icon {
          line-height: 26px;

          &:focus,
          &:hover {
            background: #3246d2;

            &:after {
              color: #dc3545;
            }
          }
          &:after {
            background: #3246d2;
            color: #fff;
            transition: color 0.3s ease-out;
          }
        }
      }
    }
    .multiselect__option div {
      white-space: pre-wrap;
    }
    .multiselect__option {
      min-height: 30px;
      font-size: 0.875rem;
      padding: 8px 12px 6px 12px;
    }
    .multiselect__option--highlight {
      background: #3246d2;
    }
    // .multiselect__option--selected:hover {
    //   background: #dc3545;
    // }
  }
  .single-select-clear {
    position: absolute;
    top: 6px;
    cursor: pointer;
    right: 44px;

    i {
      color: #999;
      font-size: 0.875rem;
      transition: color 0.3s ease-out;

      &:hover {
        color: #dc3545;
      }
    }
  }
}

</style>
